/* website: 2918-subarumetropolitain
 * created at 2020-01-15 12:00 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/subaru-old.scss";
@import "../utils/icons.scss";

.widget-sr{
  &.hide-credit-fees{
    .tile-payment-detail{
      &.small{
        display: none;
      }
    }
  }
}